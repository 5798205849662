import React, { useState } from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";

function App() {
  const [firstName,setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email,setEmail] = useState("");
  const [phone,setPhone] = useState("");
  const [session,setSession] = useState("");
  const [sessionPackage,setSessionPackage] = useState("");
  const [gender,setGender] = useState("");
  const navigate = useNavigate();
  function HandleEmail (e) {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };
  function validateEmail (value) {
    const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(value.match(mailformat)){
      console.log("email valid");
      return true;
    }
    else {
      console.log("! email invalid");
      return false;
    }
  }
  function HandlePhone (e) {
    setPhone(e.target.value);
    validatePhone(e.target.value);
  }
  function validatePhone (value) {
    const phoneformat = /^[6-9]\d{9}$/;
    if(value.match(phoneformat)){
      console.log("phone valid");
      return true;
    }
    else {
      console.log("phone invalid");
      return false;
    }
  }
  function handleGender (e) {
    console.log(e.target.value);
    setGender(e.target.value);
  }
  function handleSession (e) {
    setSession(e.target.value);
  }
  async function handleSubmit  (e)  {
    e.preventDefault();
    const data = await JSON.stringify({
      firstName,
      lastName,
      gender,
      phone,
      email,
      session,
      sessionPackage
    });
    console.log(JSON.stringify(data));
    const res = await fetch("http://172.16.2.118:8080/register",{
      method: "POST",
      mode:"cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: data
    });
    console.log(res);
    const text = await res.json();
    console.log(text.res);
    if(text.res ==="ok"){
      console.log("okk")
      navigate("/payment",{state: {id:text.id}})
    }
    else if(text.res===0){
      alert("this email already registered,please provide different email")
    }
    else{
      alert("server error, Please try again with sufficient data");
    }
  };
  return (
    <div  className="App">
      <form action="" onSubmit={handleSubmit}>

        <div className="form">Welcome</div>
        <div className="subtitle">Let's Register You</div>
        <div className="input-container ic1">
          <label htmlFor="firstname">First Name:-</label>

          <input
            type=" text"
            autoComplete="off"
            value={firstName}
            onChange={(e)=> {setFirstName(e.target.value)}}
            name="firstname"
            id="firstname"
          />
          <div className="lastname">
          <label  htmlFor="lastname">Last Name:-</label>
          <input
            type=" text"
            autoComplete="off"
            value={lastName}
            onChange={(e)=>{setLastName(e.target.value)}}
            name="lastname"
            id="lastname"
          />
          </div>
        </div>

        <div className="input-container ic2">
          <label htmlFor="email"> Email:-</label>
          <input
            type="email"
            autoComplete="off"
            value={email}
            onChange={HandleEmail}
            name="email"
            id="email"
          />
        </div>
        <div className="input-container ic2">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            autoComplete="off"
            value={phone}
            onChange={HandlePhone}
            name="phone"
            id="phone"
          />
        </div>

        <div className="input-container ic2">
          <label htmlFor="gender">Gender:</label>
          <input
            type="radio"
            name="gender"
            value="male"
            onChange={handleGender}
          />
          Male
          <input
            type="radio"
            name="gender"
            value="female"
            onChange={handleGender}
          />
          Female
          <input
            type="radio"
            name="gender"
            value="Others"
            onChange={handleGender}
          />
          Others
        </div>
        <div className="input-container ic2" id="schedule">
          <label htmlFor="schedulepreference">Schedule Preference:</label>
          <input
            type="radio"
            name="session"
            value="1"
            onChange={handleSession}
          />
          Morning Session- 6:00AM to 7:00AM
          <input
            type="radio"
            name="session"
            value="2"
            onChange={handleSession}
          />
          Morning Session- 7:00AM to 8:00AM
          <input
            type="radio"
            name="session"
            value="3"
            onChange={handleSession}
          />
          Morning Session- 8:00AM to 9:00AM
          <input
            type="radio"
            name="session"
            value="4"
            onChange={handleSession}
          />
          Evening Session- 5:00PM to 6:00PM
        </div>
        <div className="input-container ic2" id="packages">
          <label htmlFor="sessionpackage">Session-Package:</label>
          <input
            type="radio"
            name="sessionpackage"
            value="500"
            onChange={(e)=>{setSessionPackage(e.target.value)}}
          />
          500Rs Per-Month
        </div>
        <button className="submit"  type="submit">Registration</button>
      </form>
    </div>
  );
}

export default App;
