import React, {useState, useEffect} from 'react'
import  "./Payment.css"
import { useLocation } from 'react-router-dom';



function Payment() {
    const [name,setname]=useState("");
    const [card,setcard]=useState("");
    const [id,setId] = useState("");
    const [feeCurrent,setFeeCurrent] = useState(false);
    const [feeNextMonth,setFeeNextMonth] = useState(false);
    const [submitCurrent,setSubmitCurrent] = useState(false);
    const [submitNext,setSubmitNext] = useState(false);
    const data = useLocation();
    console.log(feeCurrent,feeNextMonth);
    function handlesecondCheckbox (e) {
        if(!e.target.checked) {
            setSubmitNext(false);
        }
        else {
            setSubmitCurrent(true);
            setSubmitNext(true);
        }
    }
    function handleFirstCheckbox(e) {
        if(!e.target.checked) {
            setSubmitCurrent(false);
            setSubmitNext(false);
        }
        else {
            setSubmitCurrent(true);
        }
    }
    async function getPaymentStatus(sid) {
        let lid = id || sid; 
        const res = await fetch(`http://172.16.2.118:8080/feeDetails?id=${lid}`);
        const json = await res.json();
        if(json.code==1) {
            alert(json.res);
            return;
            setFeeCurrent(false);
            setFeeNextMonth(false);
        }
        setFeeCurrent(json.fee_current);
        setFeeNextMonth(json.fee_next_month);
        setSubmitCurrent(json.fee_current);
        setSubmitNext(json.fee_next_month);
        console.log(json);
    }
    useEffect( ()=>{
        console.log(data);
        if(data.state) {
            if(data.state.id) {
                alert(`sucessfully registered your id is ${data.state.id}`)
            setId(data.state.id);
            getPaymentStatus(data.state.id);
            }
        }
    },[])
    // useEffect(()=>{
    //     getPaymentStatus();
    // },[])
   async function handleSubmit(e){
        e.preventDefault();
        if(!id) {
            alert("please enter id");
            return;
        };
        const data = await JSON.stringify({
        id,
        fee_current:submitCurrent,
        fee_next_month:submitNext,
           name,
           card
          });
          console.log(JSON.stringify(data));
          const res = await fetch("http://172.16.2.118:8080/payment",{
            method: "POST",
            mode:"cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: data
          });
        const jsonres = await res.json();
        console.log(jsonres)
            alert(jsonres.res);
          if(jsonres.code===1) {
            setname("");
            setFeeCurrent(false);
            setFeeNextMonth(false);
            setId("");
            setSubmitNext(false);
            setcard("");
            setSubmitCurrent(false)
          }
    }

  return (
    <form onSubmit={handleSubmit}>
        <label id="id" className="label" htmlFor="id">id</label>
        <input type="number" id="id" value={id} onChange={(e)=>{setId(e.target.value)}} name="id" />
        <button type="button" onClick={getPaymentStatus}>get payment status</button>
        <table id='tab1'>
            <tr>
                <th>Current month fees</th>
                <th>Next month fees</th>
            </tr>
            <tr>
                <td>{feeCurrent?"submitted":"not_submitted"}</td>
                <td>{feeNextMonth?"submitted":"not_submitted"}</td>
            </tr>
            
        </table>
        <label htmlFor="">Submit fee for:-</label><br />
        <input type="checkbox" id="currentmonth" name="currentmonth" onClick={handleFirstCheckbox} disabled={feeCurrent} checked={submitCurrent}/>
        <label for="vehicle1"> Current Month</label><br></br>
        <input type="checkbox" id="nextmonth" name="nextmonth" onClick={handlesecondCheckbox} disabled={feeNextMonth} checked={submitNext}/>
        <label for="vehicle2">Next Month</label><br />
        <label id='namelabel' className="label"  htmlFor="name">
         Name 
        
        </label>
        <input onChange={(e)=>{setname(e.target.value)}} value={name} type="text" id='name' name='name' />

        <label id='cardlabel' className="label" htmlFor="card">
          Your Card Details 
        </label>
        <input onChange={(e)=>{setcard(e.target.value)}} type="number"  id='card' value={card} name='card' /> 

        <button id='submitbutton' type='submit'>Submit</button>
        
        
    </form>
  )
}

export default Payment
